// src/components/Header/Header.jsx
import React, { useState } from 'react';
import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  Box,
  Menu,
  MenuItem,
  IconButton,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import LanguageIcon from '@mui/icons-material/Language';

const Header = () => {
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = useState(null);

  const handleLogin = () => {
    navigate('/login');
  };

  const handleRegister = () => {
    navigate('/register');
  };

  const handleLanguageClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleLanguageClose = () => {
    setAnchorEl(null);
  };

  const handleLanguageSelect = (language) => {
    // Implementa la lógica para cambiar el idioma aquí
    console.log(`Idioma seleccionado: ${language}`);
    setAnchorEl(null);
  };

  return (
    <AppBar
      position="fixed"
      elevation={4} // Añade sombra
      sx={{
        backgroundColor: '#FFFFFF', // Fondo blanco
        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)', // Sombra personalizada
      }}
    >
      <Toolbar>
        {/* Logo o Nombre de la Marca */}
        <Typography
          variant="h6"
          component="div"
          sx={{ flexGrow: 1, cursor: 'pointer', color: '#000000' }} // Asegura que el texto sea negro
          onClick={() => navigate('/')}
        >
          Viewser
        </Typography>

        {/* Selección de Idioma */}
        <Box>
          <IconButton
            size="large"
            edge="end"
            color="default"
            aria-label="idioma"
            onClick={handleLanguageClick}
          >
            <LanguageIcon />
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleLanguageClose}
          >
            <MenuItem onClick={() => handleLanguageSelect('Español')}>
              Español
            </MenuItem>
            <MenuItem onClick={() => handleLanguageSelect('Inglés')}>
              Inglés
            </MenuItem>
            {/* Añade más idiomas según sea necesario */}
          </Menu>
        </Box>

        {/* Botones de Iniciar Sesión y Registrarse */}
        <Box sx={{ display: 'flex', gap: 2, ml: 2 }}>
          <Button
            variant="outlined"
            color="primary"
            onClick={handleLogin}
            sx={{
              borderRadius: '20px', // Bordes redondeados
              textTransform: 'none', // Evita mayúsculas automáticas
              paddingX: 3, // Ajusta el padding horizontal
            }}
          >
            Iniciar Sesión
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleRegister}
            sx={{
              borderRadius: '20px', // Bordes redondeados
              textTransform: 'none', // Evita mayúsculas automáticas
              paddingX: 3, // Ajusta el padding horizontal
            }}
          >
            Registrarse
          </Button>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
