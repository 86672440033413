// src/components/Hero/Hero.jsx
import React from 'react';
import { Box, Typography, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import leftImage from '../../assets/left-image.png'; // Reemplaza con la ruta de tu imagen
import rightImage from '../../assets/right-image.png'; // Reemplaza con la ruta de tu imagen

const Hero = () => {
  const navigate = useNavigate();

  const handleStart = () => {
    navigate('/login');
  };

  return (
    <Box
      sx={{
        position: 'relative',
        width: '100%',
        minHeight: '100vh',
        backgroundImage: 'linear-gradient(to bottom, #f5f5f5, #ffffff)', // Degradado gris muy suave a blanco
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        overflow: 'hidden',
        padding: { xs: 2, sm: 4, md: 8 },
      }}
    >
      {/* Imágenes Laterales */}
      <Box
        component="img"
        src={leftImage}
        alt="Left Decoration"
        sx={{
          position: 'absolute',
          left: '-140px', // Posicionar más fuera de la pantalla
          top: '50%',
          transform: 'translateY(-50%)',
          width: { xs: '250px', sm: '350px', md: '450px' }, // Tamaño aumentado
          pointerEvents: 'none',
          opacity: 0.5
        }}
      />
      <Box
        component="img"
        src={rightImage}
        alt="Right Decoration"
        sx={{
          position: 'absolute',
          right: '-140px', // Posicionar más fuera de la pantalla
          top: '50%',
          transform: 'translateY(-50%)',
          width: { xs: '250px', sm: '350px', md: '450px' }, // Tamaño aumentado
          pointerEvents: 'none',
          opacity: 0.5
        }}
      />

      {/* Contenido Central */}
      <Box
        sx={{
          textAlign: 'center',
          maxWidth: '800px',
        }}
      >
        <Typography
          variant="h4"
          sx={{
            color: '#4F4F4F',
            mb: 2,
            fontSize:'1.6rem'
          }}
        >
          Make Accessibility Happen
        </Typography>
        <Typography
          variant="h2"
          sx={{
            color: '#000000',
            textShadow: '#7894CC 1px 0 19.5px;',
            mb: 4,
            fontSize: { xs: '2.5rem', sm: '3rem', md: '3.5rem' },
            fontWeight:'bold' // Tamaño ajustado
          }}
        >
          <Box component="span" sx={{ color: '#0F52BD' }}>
            Viewser
          </Box>
          , tu widget de{' '}
          <Box component="span" sx={{ color: '#0F52BD', textShadow: '#7894CC 1px 0 19.5px;', }}>
            accesibilidad
          </Box>{' '}
          web
        </Typography>
        <Button
          variant="contained"
          color="primary"
          onClick={handleStart}
          sx={{
            borderRadius: '50px', // Botón completamente redondeado
            textTransform: 'none',
            paddingX: 6,
            paddingY: 1.5,
            fontSize: '1.2rem',
          }}
        >
          Empezar
        </Button>
      </Box>
    </Box>
  );
};

export default Hero;
