// src/services/prismaClient.js
import axios from 'axios';

// Configura la URL base de tu API de Prisma
const API_BASE_URL = process.env.REACT_APP_PRISMA_API_URL;

export const prismaClient = axios.create({
  baseURL: API_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
    // Agrega encabezados adicionales si tu API los requiere, como autenticación
  },
});
