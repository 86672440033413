import React, { useState, useEffect } from 'react';
import { Routes, Route, Link, Navigate, useLocation, useNavigate } from 'react-router-dom';
import {
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Box,
  Divider,
  Collapse,
} from '@mui/material';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline'; 
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined'; 
import ExpandLess from '@mui/icons-material/ExpandLess'; 
import ExpandMore from '@mui/icons-material/ExpandMore'; 
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined'; 
/* import LanguageOutlinedIcon from '@mui/icons-material/LanguageOutlined';  */
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import Perfil from '../pages/Dashboard/Perfil';
import Servicios from '../pages/Dashboard/Widget';
import Pricing from '../pages/Dashboard/ContratarWidgetPricing'
import ModificarPlan from '../pages/Dashboard/ModificarPlan';
import WidgetStatistics from '../pages/Dashboard/WidgetStatistics';
import { useAuth } from '../contexts/AuthContext';
import Logo from '../assets/logo-viewser.png';
import Home from '../pages/Dashboard/Home';
import api from '../services/api';

const drawerWidth = 240;

const Dashboard = () => {
  const { user, signOut } = useAuth(); 
  const location = useLocation(); 
  const navigate = useNavigate();
  const [openProfile, setOpenProfile] = useState(false);
  const [customerName, setCustomerName] = useState(''); 

  const handleLogout = async () => {
    await signOut();
    navigate('/');
  };

  useEffect(() => {
    const fetchCustomerName = async () => {
      try {
        const response = await api.get(`/customer-name/${user.id}`);
        setCustomerName(response.data.customerName);
      } catch (error) {
        console.error('Error al obtener el nombre del cliente:', error);
      }
    };

    const fetchData = async () => {
      await Promise.all([fetchCustomerName()]);
    };

    fetchData();
  }, [user.id]);

  if (!user) {
    return <Navigate to="/login" />;
  }

  const handleProfileClick = () => {
    setOpenProfile(!openProfile);
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <Drawer
        variant="permanent"
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          [`& .MuiDrawer-paper`]: {
            width: drawerWidth,
            boxSizing: 'border-box',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            borderRight: 'none', 
            boxShadow: '-6px 22px 49px rgba(15, 82, 189, 0.46)',
          },
        }}
      >
        <Box>
          {/* Logo */}
          <Box sx={{ p: 2, textAlign: 'center' }}>
            <Box
              component="img"
              src={Logo}
              alt="Logo de Viewser"
              sx={{ width: '140px', mb: 1, mt:3, mr:6 }}
            />
          </Box>
          {/* No hay Divider aquí */}

          {/* Links */}
          <List>
            <ListItem button component={Link} to="home" selected={location.pathname === '/dashboard/home'}>
              <ListItemIcon
                sx={{
                  minWidth: '30px', 
                  marginRight: '8px',
                  marginLeft:'12px', 
                }}
              >
                <HomeOutlinedIcon 
                  sx={{
                    fontSize: '32px', 
                    color: location.pathname === '/dashboard/home' ? '#0F52BD' : '#000000',
                  }}
                />
              </ListItemIcon>
              <ListItemText primary="Inicio" primaryTypographyProps={{
                      fontSize: '17px',
                      color: location.pathname === '/dashboard/home' ? '#000000' : '#4F4F4F',
                      fontWeight: 'normal'
                    }}/>
            </ListItem>

            {/* Divider entre Home y Widget */}
            <Divider 
              sx={{
                marginX: 2, // Margen horizontal para acortar la línea
                backgroundColor: '#7894CC', 
              }}           
            />

            <ListItem button component={Link} to="widget" selected={location.pathname === '/dashboard/widget'}>
              <ListItemIcon
                sx={{
                  minWidth: '30px', // Reduce el ancho mínimo del contenedor de icono para acercarlo al texto
                  marginRight: '8px',
                  marginLeft:'12px' 
                }}
              >
                <img
                  src={
                    location.pathname === '/dashboard/widget'
                      ? require('../assets/icono-viewser-activado.svg').default // SVG con color diferente
                      : require('../assets/icono-viewser.svg').default
                  }
                  alt="Custom Icon"
                  style={{
                    width: '32px',
                    height: '32px',
                  }}
                />
              </ListItemIcon>
              <ListItemText primary="Widget" primaryTypographyProps={{
                      fontSize: '17px',
                      color: location.pathname === '/dashboard/widget' ? '#000' : '#4F4F4F',
                      fontWeight: 'normal'
                    }}/>
            </ListItem>
          </List>
        </Box>

        
        <Box>
          <List>
            <Collapse in={openProfile} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItem button component={Link} to="perfil" sx={{ pl: 4 }} selected={location.pathname === '/dashboard/perfil'}>
                  <ListItemIcon
                    sx={{
                      minWidth: '30px', // Reduce el ancho mínimo del contenedor de icono para acercarlo al texto
                      marginRight: '8px', 
                    }}
                  >
                    <SettingsOutlinedIcon 
                      sx={{
                        fontSize: '26px',
                        color: location.pathname === '/dashboard/perfil' ? '#0F52BD' : '#4F4F4F' 
                      }}
                    />
                  </ListItemIcon>
                  <ListItemText
                    primary="Perfil"
                    primaryTypographyProps={{
                      fontSize: '14px',
                      color: location.pathname === '/dashboard/perfil' ? '#000' : '#4F4F4F',
                    }}
                  />
                </ListItem>
                {/* <ListItem button sx={{ pl: 4 }}>
                  <ListItemIcon
                    sx={{
                      minWidth: '30px', // Reduce el ancho mínimo del contenedor de icono para acercarlo al texto
                      marginRight: '8px', 
                    }}
                  >
                    <LanguageOutlinedIcon 
                      sx={{
                        fontSize: '26px', 
                      }}
                    />
                  </ListItemIcon>
                  <ListItemText
                    primary="Idioma"
                    primaryTypographyProps={{
                      fontSize: '14px',
                      color: '#4F4F4F',
                    }}
                  />
                </ListItem> */}
                <ListItem
                  button
                  onClick={handleLogout}
                  sx={{
                    pl: 4,
                    cursor: 'pointer', // Cambia el cursor al de clic
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: '30px', // Reduce el ancho mínimo del contenedor de icono para acercarlo al texto
                      marginRight: '8px', 
                    }}
                  >
                    <LogoutOutlinedIcon 
                      sx={{
                        fontSize: '26px', 
                      }}
                    />
                  </ListItemIcon>
                  <ListItemText
                    primary="Cerrar sesión"
                    primaryTypographyProps={{
                      fontSize: '14px',
                      color: '#4F4F4F',
                    }}
                  />
                </ListItem>
              </List>
            </Collapse>


            {/* Botón de perfil */}
            <Divider 
              sx={{
                marginX: 2, // Margen horizontal para acortar la línea
                backgroundColor: '#7894CC', 
              }} 
            />
            <ListItem button onClick={handleProfileClick}>
              <ListItemIcon
                sx={{
                  minWidth: '30px', // Reduce el ancho mínimo del contenedor de icono para acercarlo al texto
                  marginRight: '8px', 
                  marginLeft:'12px'
                }}
              >
              <PersonOutlineIcon
                sx={{
                  fontSize: '32px', // Ajusta el tamaño a 24x24
                  color: '#000000', 
                }}
              />
              </ListItemIcon>
              <ListItemText
                    primary={customerName}
                    primaryTypographyProps={{
                      fontSize: '17px',
                      color: '#000000',
                    }}
                  />
              {openProfile ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
          </List>
        </Box>
      </Drawer>

      <Box
        component="main"
        sx={{
          flexGrow: 1,
          bgcolor: 'background.default',
          p: 3,
          marginLeft: `${drawerWidth - 180}px`,
        }}
      >
        <Routes>
          <Route path="home" element={<Home/>} />
          <Route path="widget" element={<Servicios />} />
          <Route path="perfil" element={<Perfil />} />
          <Route path='pricing' element={<Pricing/>}/>
          <Route path='modify-plan' element={<ModificarPlan/>}/>
          <Route path=':domain/estadisticas' element={<WidgetStatistics/>}/>
          <Route path="*" element={<Navigate to="home" />} />
        </Routes>
      </Box>
    </Box>
  );
};

export default Dashboard;
