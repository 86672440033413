// src/pages/Dashboard/WidgetStatistics.jsx
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  CircularProgress,
  Typography,
  Container,
  Grid,
  Card,
  CardContent,
  Box,
  Tooltip
} from '@mui/material';
import LanguageOutlinedIcon from '@mui/icons-material/LanguageOutlined'; 
import AccessibilityIcon from '@mui/icons-material/Accessibility';
import { BarChart, Bar, XAxis, YAxis,  ResponsiveContainer, Legend, LabelList, CartesianGrid, Cell } from 'recharts';
import iconoViewser from '../../assets/icono-viewser.svg';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import api from '../../services/api';

const WidgetStatistics = () => {
  const { domain } = useParams();
  const [metrics, setMetrics] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false)

  const fetchMetrics = async () => {
    const now = Date.now();
    const lastFetch = localStorage.getItem(`metrics-timestamp-${domain}`);
    const cachedMetrics = localStorage.getItem(`metrics-${domain}`);

    if (lastFetch && now - lastFetch < 60 * 60 * 1000 && cachedMetrics) {
      setMetrics(JSON.parse(cachedMetrics));
      setLoading(false);
      return;
    }

    try {
      const response = await api.post('/get-metrics', { domain });

      setMetrics(response.data);
      localStorage.setItem(`metrics-${domain}`, JSON.stringify(response.data));
      localStorage.setItem(`metrics-timestamp-${domain}`, now);
    } catch (err) {
      console.error('Error al obtener métricas:', err);
      if (cachedMetrics) {
        setMetrics(JSON.parse(cachedMetrics));
        setError('Error al actualizar. Mostrando métricas anteriores.');
        console.error(error)
      } else {
        setError('No se pudieron obtener las métricas.');
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchMetrics();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [domain]);

  if (loading) {
    return (
      <Container maxWidth="sm" sx={{ mt: 10, textAlign: 'center' }}>
        <CircularProgress />
        <Typography sx={{ mt: 2 }}>Cargando métricas...</Typography>
      </Container>
    );
  }

  // Procesar datos
  const accessProfile = metrics.find(([profile]) => profile === 'access_profile')?.[1] || 0;

  const otherProfiles = metrics.filter(([profile]) => profile !== 'access_profile');

  const mostUsedProfile = otherProfiles.reduce((max, current) => (current[1] > max[1] ? current : max), ['', 0]);
  const profileTranslations = {
    visual_disability_profile: 'Discapacidad visual',
    learning_profile: 'Aprendizaje',
    motor_skills_disorder_profile: 'Trastorno motor',
    blindness_profile: 'Ceguera',
    epilepsy_profile: 'Epilepsia',
    achromatopsia_profile: 'Acromatopsia',
    deuteranopia_profile: 'Deuteranopía',
    tdah_profile: 'TDAH',
    tritanomaly_profile: 'Tritanomalía',
    deuteranomaly_profile: 'Deuteranomalía',
    tritanopia_profile: 'Tritanopía',
    elderly_profile: 'Personas mayores',
    dislexy_profile: 'Dislexia',
    protanopia_profile: 'Protanopía',
  };

  const translateProfileName = (profile) => {
    const translations = {
      visual_disability: 'Discapacidad visual',
      learning: 'Dificultades de aprendizaje',
      motor_skills_disorder_profile: 'Trastorno motor',
      blindness: 'Ceguera',
      epilepsy: 'Epilepsia',
      achromatopsia: 'Acromatopsia',
      deuteranopia: 'Deuteranopía',
      tdah: 'TDAH',
      tritanomaly: 'Tritanomalía',
      deuteranomaly: 'Deuteranomalía',
      tritanopia: 'Tritanopía',
      elderly: 'Personas mayores',
      dislexy: 'Dislexia',
      protanopia: 'Protanopía',
    };
  
    return translations[profile] || profile;
  };
  
  // Procesar datos
  const chartData = metrics
  .filter(([profile]) => profile !== 'access_profile')
  .map(([profile, count]) => ({
    name: profileTranslations[profile] || profile,
    value: count,
    isMostUsed: profile === mostUsedProfile[0],
  }));

  const renderCustomLabel = (props) => {
    const { x, y, width, height, value, index } = props;
    const entry = chartData[index];
    return (
      <text
        x={x + width - 10} // Ajusta la posición horizontal según sea necesario
        y={y + height / 2}
        fill={entry.isMostUsed ? '#FFFFFF' : '#0F52BD'} // Blanco para la más usada, azul para las demás
        fontWeight="medium"
        fontSize={16}
        textAnchor="end"
        dominantBaseline="middle"
      >
        {value}
      </text>
    );
  };


  return (
    <Container maxWidth="xl" sx={{ mt: 7 }}>
      <Typography variant="h4" gutterBottom sx={{ display: 'flex', alignItems: 'center', mb:5, fontWeight:'medium', fontSize:'34px' }}>
        Estadísticas para{' '}
        <Typography
            component="span"
            sx={{
            fontWeight: 'medium',
            color: '#0F52BD', // Cambia el color del dominio
            marginLeft: '8px', // Espaciado entre el texto y el dominio
            fontSize: 'inherit', // Asegura que el tamaño coincide con el título principal
            }}
        >
            {domain}
        </Typography>
        </Typography>

      <Grid container spacing={3} sx={{ alignItems: 'stretch' }}>
  {/* Tarjeta 2: Usuarios afectados */}
        <Grid item xs={12} sm={6} md={3} sx={{ display: 'flex' }}>
        <Card
            sx={{
            boxShadow: '0px 4px 10px 0px rgba(15, 82, 189, 0.46)',
            flex: 1,
            borderRadius: 3,
            position: 'relative',
            }}
        >
            <CardContent>
            <Box display="flex" alignItems="center" justifyContent="space-between" mb={2}>
                <Box display="flex" alignItems="center">
                <LanguageOutlinedIcon sx={{ mr: 1, color: '#595959' }} />
                <Typography sx={{ fontSize: '16px', color: '#000' }}>Usuarios afectados</Typography>
                </Box>
                {/* Icono de información */}
                <Tooltip
                    title={
                        <Typography sx={{ fontSize: '14px', color: '#fff' }}>
                        Número de usuarios que han usado el widget en los últimos 30 días.
                        </Typography>
                    }
                    arrow
                    placement="top"
                    componentsProps={{
                        tooltip: {
                        sx: {
                            backgroundColor: '#0F52BD', // Fondo azul sólido
                            boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.2)', // Opcional: sombra para el tooltip
                            padding: '10px', // Espaciado interno
                            borderRadius: '5px', // Bordes redondeados
                        },
                        },
                        arrow: {
                        sx: {
                            color: '#0F52BD', // Fondo azul para la flecha
                        },
                        },
                    }}
                >
                <InfoOutlinedIcon sx={{ fontSize: '20px', color: '#595959', cursor: 'pointer' }} />
                </Tooltip>
            </Box>

            {/* Total abajo a la derecha */}
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '120px', // Ajusta la altura según sea necesario
              }}
            >
              <Typography
                variant="h5"
                sx={{
                  fontSize: '48px', // Ajusta el tamaño de la fuente según sea necesario
                  fontWeight: 'medium',
                  color: '#000',
                }}
              >
                {accessProfile}
              </Typography>
            </Box>
            </CardContent>
        </Card>
        </Grid>

        {/* Tarjeta 3: Perfil de accesibilidad más usado */}
        <Grid item xs={12} sm={6} md={3} sx={{ display: 'flex' }}>
            <Card sx={{ boxShadow: '0px 4px 10px 0px rgba(15, 82, 189, 0.46)', flex: 1, borderRadius: 3, position: 'relative' }}>
                <CardContent>
                <Box display="flex" alignItems="center" justifyContent="space-between" mb={2}>
                    <Box display="flex" alignItems="center">
                    <AccessibilityIcon sx={{ mr: 1, color: '#595959' }} />
                    <Typography sx={{ fontSize: '16px', color: '#000' }}>Perfil de accesibilidad más usado</Typography>
                    </Box>
                    {/* Icono de información */}
                    <Tooltip
                        title={
                            <Typography sx={{ fontSize: '14px', color: '#fff' }}>
                            Perfil de accesibilidad más usado por tus usuarios en los últimos 30 días.
                            </Typography>
                        }
                        arrow
                        placement="top"
                        componentsProps={{
                            tooltip: {
                            sx: {
                                backgroundColor: '#0F52BD', // Fondo azul sólido
                                boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.2)', // Opcional: sombra para el tooltip
                                padding: '10px', // Espaciado interno
                                borderRadius: '5px', // Bordes redondeados
                            },
                            },
                            arrow: {
                            sx: {
                                color: '#0F52BD', // Fondo azul para la flecha
                            },
                            },
                        }}
                    >
                    <InfoOutlinedIcon sx={{ fontSize: '20px', color: '#595959', cursor: 'pointer' }} />
                    </Tooltip>
                </Box>

                {/* Tarjeta interna con el perfil */}
                <Box
                    sx={{
                    position: 'relative',
                    p: 2,
                    mt: 7,
                    mb: 2,
                    maxWidth: '60%',
                    mx: 'auto',
                    boxShadow: '0px 4px 10px 0px rgba(15, 82, 189, 0.46)',
                    borderRadius: 3,
                    minHeight: '80px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    }}
                >
                    {/* Icono #1 */}
                    <Box
                    sx={{
                        position: 'absolute',
                        top: '-10px',
                        right: '-10px',
                        width: '40px',
                        height: '40px',
                        backgroundColor: '#0F52BD',
                        color: '#fff',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        fontSize: '14px',
                        fontWeight: 'bold',
                        borderRadius: '20%',
                        boxShadow: '0px 4px 10px 0px rgba(15, 82, 189, 0.46)',
                    }}
                    >
                    #1
                    </Box>

                    <Typography variant="subtitle1" sx={{ fontSize: '18px', color: '#000', textAlign: 'center', fontWeight: 'medium' }}>
                    {mostUsedProfile[0]
                        ? translateProfileName(mostUsedProfile[0].replace('_profile', '').trim())
                        : 'N/A'}
                    </Typography>
                </Box>
                </CardContent>
            </Card>
        </Grid>

        {/* Tarjeta 4: Próximamente */}
        <Grid item xs={12} sm={6} md={3} sx={{ display: 'flex' }}>
          <Card sx={{boxShadow: '0px 4px 10px 0px rgba(15, 82, 189, 0.46)', flex:1, borderRadius:3}}>
            <CardContent>
              <Box display="flex" alignItems="center" mb={2}>
              <Box
                component="img"
                src={iconoViewser}// Asegúrate de colocar la ruta correcta de la imagen
                alt="Próximamente"
                sx={{
                    width: '24px', // Ajusta el tamaño según sea necesario
                    height: '24px',
                    mr: 1, // Margen derecho
                }}
              />
                <Typography sx={{fontSize:'16px', color:'#000'}}>Próximamente</Typography>
              </Box>
              
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      {/* Sección: Perfiles de accesibilidad más usados */}
      <Box mt={8}>
    <Typography variant="h5" sx={{fontWeight:'medium', fontSize:'34px'}}>
        Perfiles de accesibilidad más usados
    </Typography>
    <Typography variant="body2" sx={{color:'#4F4F4F', fontWeight:'light', mb:4, fontSize:'16px'}}>
        Perfiles de accesibilidad que más clicks han recibido en el último mes
    </Typography>
    {metrics.length === 0 ? (
        <Box sx={{ textAlign: 'center', mt: 5 }}>
          <Typography variant="h6" color="text.secondary">
            No tienes métricas disponibles.
          </Typography>
        </Box>
      ) : (
  <Card sx={{ p: 2, boxShadow: '0px 4px 10px 0px rgba(15, 82, 189, 0.46)' }}>
    <Box sx={{ width: '100%', mx: '0' }}> {/* Gráfica centrada */}
      <ResponsiveContainer width="100%" height={700}>
      <BarChart
        data={chartData}
        layout="vertical"
        margin={{ top: 20, right: 30, left: 20, bottom: 20 }}
        barCategoryGap={40}
      >
        <CartesianGrid 
          strokeDasharray="5 5" 
          horizontal={false} // Desactiva líneas horizontales
          vertical={true} // Activa líneas verticales desde el eje X
          strokeWidth={2}
          stroke="#D9D9D9"
        />
        {/* Eje X con líneas visibles */}
        <XAxis
          type="number"
          tick={{ fontSize: 14 }}
          axisLine={{ stroke: '#595959' }}
          tickLine={false} // Desactiva las pequeñas líneas de los ticks
          stroke="#595959"
        />
        {/* Eje Y con línea visible */}
        <YAxis
          dataKey="name"
          type="category"
          tickLine={false}
          width={160}
          axisLine={{ stroke: '#595959' }}
          tick={({ x, y, payload }) => {
            const entry = chartData[payload.index];
            return (
              <text
                x={x - 10}
                y={y}
                fill={entry.isMostUsed ? '#000' : '#595959'}
                fontWeight={entry.isMostUsed ? 'medium' : 'normal'}
                fontSize={16}
                textAnchor="end"
              >
                {payload.value}
              </text>
            );
          }}
        />
        <Tooltip />
        <Legend
          formatter={(value) => (value === 'value' ? 'Usuarios' : value)} 
        />
        <Bar dataKey="value" barSize={30} radius={[0, 5, 5, 0]}>
          {chartData.map((entry, index) => (
            <Cell
              key={`cell-${index}`}
              fill={entry.isMostUsed ? '#0F52BD' : '#C7D7F0'}
              stroke={entry.isMostUsed ? '#0A3E91' : '#7894CC'}
              strokeWidth={2}
            />
          ))}
          <LabelList content={renderCustomLabel} />
        </Bar>
      </BarChart>
      </ResponsiveContainer>
    </Box>
  </Card>
      )}
</Box>


    </Container>
  );
};

export default WidgetStatistics;
