// src/pages/Perfil.jsx
import React, { useState, useEffect } from 'react';
import {
  Typography,
  Box,
  Button,
  TextField,
  MenuItem,
  Grid,
  Alert,
  CircularProgress,
  Paper,
  Container,
} from '@mui/material';
import { useAuth } from '../../contexts/AuthContext';
import api from '../../services/api';
import ChangePasswordModal from '../../components/ChangePasswordModal';

const Perfil = () => {
  const { user } = useAuth();
  const [formData, setFormData] = useState({
    email: '',
    tipoCuenta: '', // 'Consumer' o 'Business'
    customer_name: '',
    date_of_birth: '',
    numeroTelefono: '',
    billing_cycle: '',
    billing_address: {
      postal_code: '',
      region: '',
      city: '',
      address: '',
      country_code: '',
    },
    fiscal_identification: '',
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [isEditing, setIsEditing] = useState(false);

  // Estado para controlar el modal
  const [openChangePassword, setOpenChangePassword] = useState(false);

  useEffect(() => {
    const fetchCustomerDetails = async () => {
      try {
        const response = await api.get(`/customer-details?user_id=${user.id}`);
        console.log(response.data);
        setFormData({
          email: response.data.email || '',
          tipoCuenta: response.data.tipoCuenta || '',
          customer_name: response.data.nombre || '',
          date_of_birth: response.data.fechaNacimiento || '',
          numeroTelefono: response.data.telefono || '',
          billing_cycle: response.data.billing_cycle || '',
          billing_address: {
            postal_code: response.data.billing_address.postal_code || '',
            region: response.data.billing_address.region || '',
            city: response.data.billing_address.city || '',
            address: response.data.billing_address.address || '',
            country_code: response.data.billing_address.country_code || '',
          },
          fiscal_identification: response.data.fiscal_identification || '',
        });
      } catch (err) {
        console.error('Error al obtener detalles del cliente:', err);
        setError('No se pudieron obtener los detalles del cliente.');
      } finally {
        setLoading(false);
      }
    };

    if (user && user.id) {
      fetchCustomerDetails();
    }
  }, [user]);

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name.startsWith('billing_address.')) {
      const field = name.split('.')[1];
      setFormData((prev) => ({
        ...prev,
        billing_address: {
          ...prev.billing_address,
          [field]: value,
        },
      }));
    } else {
      setFormData((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setSuccess('');

    try {
      const response = await api.put('/update-customer-details', {
        user_id: user.id,
        billing_cycle: formData.billing_cycle,
        billing_address: formData.billing_address,
        fiscal_identification: formData.fiscal_identification,
        customer_name: formData.customer_name,
        numeroTelefono: formData.numeroTelefono,
        date_of_birth: formData.date_of_birth,
      });

      if (response.data.success) {
        setSuccess('Datos actualizados correctamente.');
        setIsEditing(false);
      }
    } catch (err) {
      console.error('Error al actualizar detalles del cliente:', err);
      setError(err.response?.data?.error || 'No se pudieron actualizar los datos.');
    }
  };

  const toggleEdit = () => {
    setIsEditing((prev) => !prev);
    setError('');
    setSuccess('');
  };

  // Funciones para manejar el modal
  const handleOpenChangePassword = () => {
    setOpenChangePassword(true);
  };

  const handleCloseChangePassword = () => {
    setOpenChangePassword(false);
  };

  if (loading) {
    return (
      <Box sx={{ mt: 10, textAlign: 'center' }}>
        <CircularProgress />
        <Typography sx={{ mt: 2 }}>Cargando datos...</Typography>
      </Box>
    );
  }

  return (
    <Container maxWidth="lg" sx={{ mt: 5, mb: 5 }}>
      <Typography variant="h4" gutterBottom sx={{ fontWeight: 'medium', fontSize: '34px', mb: 5, color: '#000' }}>
        Configuración de cuenta
      </Typography>

      {/* Alertas */}
      {error && <Alert severity="error" sx={{ mb: 2 }}>{error}</Alert>}
      {success && <Alert severity="success" sx={{ mb: 2 }}>{success}</Alert>}

      <Grid container spacing={4}>
        {/* Bloque Izquierdo */}
        <Grid item xs={12} md={6}>
          <Paper
            elevation={3}
            sx={{
              padding: 3,
              borderRadius: '10px',
              height: '100%',
              boxShadow: '0px 2px 25px 0px rgba(15, 82, 189, 0.46)',
            }}
          >
            {/* Información de cuenta */}
            <Box sx={{ mb: 4 }}>
              <Typography variant="h6" gutterBottom mb={3} color="#000">
                Información de cuenta
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    label="Correo Electrónico"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    fullWidth
                    disabled={true}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    select
                    label="Tipo de Cuenta"
                    name="tipoCuenta"
                    value={formData.tipoCuenta}
                    onChange={handleChange}
                    fullWidth
                    disabled={true}
                  >
                    <MenuItem value="Consumer">Personal</MenuItem>
                    <MenuItem value="Business">Empresa</MenuItem>
                  </TextField>
                </Grid>
              </Grid>
            </Box>

            {/* Información general */}
            <Box>
              <Typography variant="h6" gutterBottom mb={3} color="#000">
                Información general
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    label="País"
                    name="billing_address.country_code"
                    value={formData.billing_address.country_code}
                    onChange={handleChange}
                    fullWidth
                    disabled={true}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label={formData.tipoCuenta === 'Business' ? 'NIF' : 'DNI'}
                    name="fiscal_identification"
                    value={formData.fiscal_identification}
                    onChange={handleChange}
                    fullWidth
                    disabled={!isEditing}
                    required
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="Nombre Completo"
                    name="customer_name"
                    value={formData.customer_name}
                    onChange={handleChange}
                    fullWidth
                    disabled={!isEditing}
                    required
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="Número de Teléfono"
                    name="numeroTelefono"
                    value={formData.numeroTelefono}
                    onChange={handleChange}
                    fullWidth
                    disabled={!isEditing}
                    required
                  />
                </Grid>
                {formData.tipoCuenta === 'Consumer' && (
                  <Grid item xs={12}>
                    <TextField
                      label="Fecha de Nacimiento"
                      type="date"
                      name="date_of_birth"
                      value={formData.date_of_birth}
                      onChange={handleChange}
                      fullWidth
                      disabled={!isEditing}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>
                )}
              </Grid>
            </Box>
          </Paper>
        </Grid>

        {/* Bloque Derecho */}
        <Grid item xs={12} md={6}>
          <Grid container spacing={4} direction="column">
            {/* Datos de facturación */}
            <Grid item>
              <Paper
                elevation={3}
                sx={{
                  padding: 3,
                  boxShadow: '0px 2px 25px 0px rgba(15, 82, 189, 0.46)',
                  borderRadius: '10px',
                  height: '100%',
                }}
              >
                <Typography variant="h6" gutterBottom mb={3} color="#000">
                  Datos de facturación
                </Typography>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      select
                      label="Fecha de Facturación"
                      name="billing_cycle"
                      value={formData.billing_cycle}
                      onChange={handleChange}
                      fullWidth
                      disabled={!isEditing}
                      required
                    >
                      <MenuItem value="01">01</MenuItem>
                      <MenuItem value="08">08</MenuItem>
                      <MenuItem value="15">15</MenuItem>
                      <MenuItem value="22">22</MenuItem>
                    </TextField>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      label="Código Postal"
                      name="billing_address.postal_code"
                      value={formData.billing_address.postal_code}
                      onChange={handleChange}
                      fullWidth
                      disabled={!isEditing}
                      required
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      label="Región"
                      name="billing_address.region"
                      value={formData.billing_address.region}
                      onChange={handleChange}
                      fullWidth
                      disabled={!isEditing}
                      required
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      label="Ciudad"
                      name="billing_address.city"
                      value={formData.billing_address.city}
                      onChange={handleChange}
                      fullWidth
                      disabled={!isEditing}
                      required
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      label="Dirección"
                      name="billing_address.address"
                      value={formData.billing_address.address}
                      onChange={handleChange}
                      fullWidth
                      disabled={!isEditing}
                      required
                    />
                  </Grid>
                </Grid>
              </Paper>
            </Grid>

            {/* Seguridad y cuenta */}
            <Grid item>
              <Paper
                elevation={3}
                sx={{
                  padding: 3,
                  borderRadius: '10px',
                  height: '100%',
                  boxShadow: '0px 2px 25px 0px rgba(15, 82, 189, 0.46)',
                }}
              >
                <Typography variant="h6" gutterBottom color="#000">
                  Seguridad y cuenta
                </Typography>
                <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end' }}>
                  <Button
                    variant="contained"
                    onClick={handleOpenChangePassword}
                    sx={{ textTransform: 'none', backgroundColor: '#0F52BD', paddingX: 4, paddingY: 1 }}
                  >
                    Cambiar contraseña
                  </Button>
                </Box>
              </Paper>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      {/* Botón de Edición */}
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 4, overflow: 'hidden' }}>
        {!isEditing ? (
          <Button
            variant="contained"
            color="primary"
            onClick={toggleEdit}
            sx={{
              textTransform: 'none',
              paddingX: 3,
              paddingY: 1,
              transition: 'all 0.3s ease',
            }}
          >
            Editar perfil
          </Button>
        ) : (
          <>
            <Button
              color="primary"
              onClick={toggleEdit}
              sx={{
                textTransform: 'none',
                opacity: 0,
                transform: 'translateX(100%)',
                animation: 'slideIn 0.3s forwards',
                transition: 'all 0.3s ease',
                '@keyframes slideIn': {
                  from: { opacity: 0, transform: 'translateX(100%)' },
                  to: { opacity: 1, transform: 'translateX(0)' },
                },
              }}
            >
              Cancelar
            </Button>

            <Button
              variant="contained"
              color="primary"
              onClick={handleSubmit}
              sx={{
                textTransform: 'none',
                paddingX: 3,
                paddingY: 1,
                ml: 2,
                transition: 'all 0.3s ease',
              }}
            >
              Guardar Cambios
            </Button>
          </>
        )}
      </Box>

      {/* Modal de Cambio de Contraseña */}
      <ChangePasswordModal open={openChangePassword} handleClose={handleCloseChangePassword} />
    </Container>
  );
};

export default Perfil;
