// src/components/StepButton.jsx
import React from 'react';
import { Button, Box, Typography } from '@mui/material';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

const StepButton = ({ isSelected, label, subtext, onClick }) => {
  return (
    <Button
      variant="outlined"
      fullWidth
      onClick={onClick}
      sx={{
        borderColor: isSelected ? '#0F52BD' : '#888888', // Borde azul si está seleccionado
        color: isSelected ? '#0F52BD' : '#888888', // Texto azul si está seleccionado
        textTransform: 'none',
        justifyContent: 'space-between',
        padding: 2,
        alignItems: 'center', // Alineación vertical centrada
        mb: 2, // Espacio inferior entre botones
        backgroundColor: isSelected ? '#e0f0ff' : '#FFFFFF', // Fondo azul claro si está seleccionado
        fontWeight: isSelected ? 'bold' : 'normal', // Texto en negrita si está seleccionado
        transition: 'background-color 0.3s, border-color 0.3s, color 0.3s', // Transición suave
        '&:hover': {
          borderColor: isSelected ? '#0F52BD' : '#555555', // Mantener borde azul si está seleccionado
          backgroundColor: isSelected ? '#cce0ff' : '#e0e0e0', // Fondo más oscuro al pasar el cursor
        },
      }}
    >
      <Box sx={{ textAlign: 'left' }}>
        <Typography variant="h6" sx={{fontWeight:'bold', color:'black'}}>{label}</Typography>
        <Typography variant="body2" sx={{ color: '#555555' }}>
          {subtext}
        </Typography>
      </Box>
      <ArrowForwardIosIcon fontSize="small" sx={{ color: isSelected ? '#0F52BD' : '#888888' }} />
    </Button>
  );
};

export default StepButton;

