// src/pages/Contacto/Contacto.jsx
import React, { useState } from 'react';
import {
  Grid,
  Typography,
  TextField,
  Button,
  Box,
  Alert,
  MenuItem,
  Select,
  FormControl,
  CircularProgress
} from '@mui/material';
import contactImage from '../../assets/register-image-2.webp'; // Reemplaza con la ruta de tu imagen
import api from '../../services/api'; // Asegúrate de que `api` está correctamente configurado

const Contacto = () => {  
  const [email, setEmail] = useState('');
  const [fullName, setFullName] = useState('');
  const [phone, setPhone] = useState('');
  const [reason, setReason] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [formLoading, setFormLoading] = useState(false);

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setSuccess('');
    
    // Validaciones básicas
    if (!email || !fullName || !phone || !reason || !message) {
      setError('Por favor, completa todos los campos.');
      return;
    }

    try {
      setFormLoading(true);
      // Formatear la fecha y hora actual
      const currentDateTime = new Date();
      const formattedDateTime = `${currentDateTime.getFullYear()}-${String(currentDateTime.getMonth() + 1).padStart(2, '0')}-${String(currentDateTime.getDate()).padStart(2, '0')} ${String(currentDateTime.getHours()).padStart(2, '0')}:${String(currentDateTime.getMinutes()).padStart(2, '0')}`;

      // Preparar el payload según el formato requerido
      const payload = {
        name: fullName,
        datetime: formattedDateTime,
        email: email,
        phone: phone,
        category: reason,
        message: message
      };

      // Enviar los datos al endpoint proporcionado
      const response = await api.post('https://8poddbrli0.execute-api.eu-west-1.amazonaws.com/prod/emails/contact', payload);

      if (response.status === 200) {
        setSuccess('Tu consulta ha sido enviada exitosamente.');
        // Resetear campos
        setEmail('');
        setFullName('');
        setPhone('');
        setReason('');
        setMessage('');
      } else {
        setError('Hubo un problema al enviar tu consulta. Inténtalo de nuevo.');
      }
    } catch (err) {
      console.error('Error al enviar la consulta:', err);
      setError('Hubo un problema al enviar tu consulta. Inténtalo de nuevo.');
    } finally {
      setFormLoading(false);
    }
  };

  return (
    <Grid container sx={{ height: '100vh' }}>
      {/* Columna Izquierda - Formulario */}
      <Grid
        item
        xs={12}
        md={6}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          alignItems: 'center',
          paddingTop: { xs: 4, sm: 6 },
          paddingBottom: { xs: 2, sm: 3 },
          paddingLeft: { xs: 3, sm: 6 },
          paddingRight: { xs: 3, sm: 6 },
          height: '100%',
        }}
      >

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            flexGrow: 1,
            width: '100%',
          }}
        >
          {/* Título */}
          <Typography
            variant="h4"
            sx={{
              color: '#000000',
              textShadow: '#7894CC 1px 0 19.5px;',
              mb: 1,
              fontWeight:'700'
            }}
          >
            <Box component="span" sx={{ color: '#0F52BD', textShadow: '#7894CC 1px 0 19.5px;', }}>
              Contacta
            </Box>{' '}
            con nosotros
          </Typography>
          <Typography
            variant="subtitle1"
            sx={{
              color: '#555555',
              maxWidth: '600px',
              textAlign:'center',
              mb:4
            }}
          >
            Confirma que los datos de este formulario son correctos y nos pondremos en contacto contigo cuanto antes
          </Typography>

          {/* Contenedor del Formulario con Sombra */}
          <Box
            sx={{
              width: '100%',
              maxWidth: '500px',
              backgroundColor: '#FFFFFF',
              padding: { xs: 2, sm: 3 },
              borderRadius: '8px',
              boxShadow: '#a7bbe6 1px 0 50.5px',
            }}
          >
            {/* Formulario de Contacto */}
            <Box component="form" onSubmit={handleFormSubmit}>
              {error && <Alert severity="error" sx={{ mb: 2 }}>{error}</Alert>}
              {success && <Alert severity="success" sx={{ mb: 2 }}>{success}</Alert>}

              {/* Correo Electrónico */}
              <Typography variant="subtitle1" sx={{ fontWeight: 'bold', mb: 0 }}>
                Correo Electrónico
              </Typography>
              <TextField
                fullWidth
                placeholder="Ejemplo: juan.lopez@bemyvega.com"
                margin="dense"
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />

              {/* Nombre y Apellidos */}
              <Typography variant="subtitle1" sx={{ fontWeight: 'bold', mt: 1, mb: 0 }}>
                Nombre y Apellidos
              </Typography>
              <TextField
                fullWidth
                placeholder="Ejemplo: Juan López"
                margin="dense"
                type="text"
                value={fullName}
                onChange={(e) => setFullName(e.target.value)}
                required
              />

              {/* Número de Teléfono */}
              <Typography variant="subtitle1" sx={{ fontWeight: 'bold', mt: 1, mb: 0 }}>
                Número de Teléfono
              </Typography>
              <TextField
                fullWidth
                placeholder="Ejemplo: +34 600 123 456"
                margin="dense"
                type="tel"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                required
              />

              {/* Motivo de la Consulta */}
              <Typography variant="subtitle1" sx={{ fontWeight: 'bold', mt: 1, mb: 0 }}>
                Motivo de la consulta
              </Typography>
              <FormControl fullWidth margin="dense" required>
                <Select
                  id="reason-select"
                  value={reason}
                  onChange={(e) => setReason(e.target.value)}
                  displayEmpty
                  sx={{ textTransform: 'none' }}
                >
                  <MenuItem value="">
                    <em>Seleccione una opción</em>
                  </MenuItem>
                  <MenuItem value="Soporte Técnico">Soporte Técnico</MenuItem>
                  <MenuItem value="Consultaría">Consultoría</MenuItem>
                  <MenuItem value="Asesoría">Asesoría</MenuItem>
                  <MenuItem value="Widget">Widget</MenuItem>
                  <MenuItem value="Otros">Otros</MenuItem>
                </Select>
              </FormControl>

              {/* Mensaje */}
              <Typography variant="subtitle1" sx={{ fontWeight: 'bold', mt: 2, mb: 1 }}>
                Mensaje
              </Typography>
              <TextField
                fullWidth
                
                margin="dense"
                multiline
                rows={4}
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                required
              />

              <Button
                type="submit"
                variant="contained"
                color="primary"
                fullWidth
                sx={{
                  borderRadius: '5px',
                  textTransform: 'none',
                  paddingY: 1.2,
                  mt: 3,
                }}
                disabled={formLoading}
              >
                {formLoading ? <CircularProgress size={24} /> : 'Enviar'}
              </Button>
            </Box>
          </Box>
        </Box>
      </Grid>

      {/* Columna Derecha - Imagen */}
      <Grid
        item
        xs={false}
        md={6}
        sx={{
          display: { xs: 'none', md: 'block' },
          position: 'relative',
          height: '100%', // Asegura que ocupe toda la altura del contenedor
        }}
      >
        <Box
          component="img"
          src={contactImage} // Reemplaza con la ruta de tu imagen
          alt="Imagen de Contacto"
          sx={{
            width: '100%',
            height: '100%',
            objectFit: 'cover',
            opacity: 0.8,
          }}
        />
      </Grid>
    </Grid>
  );
};

export default Contacto;
