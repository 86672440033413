// src/pages/Landing/Landing.jsx
import React from 'react';
import Header from './Header';
import Hero from './Hero';

const Landing = () => {
  return (
    <>
      <Header />
      <Hero />
    </>
  );
};

export default Landing;
