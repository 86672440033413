// src/pages/Dashboard/PaymentSuccess.jsx
import React, { useEffect, useState, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import api from '../../services/api';
import { CircularProgress, Typography, Container, Alert, Button } from '@mui/material';

const PaymentSuccess = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [status, setStatus] = useState('loading');
  const [message, setMessage] = useState('');
  const hasFetched = useRef(false); // Flag para evitar múltiples llamadas

  useEffect(() => {
    if (hasFetched.current) return;
    hasFetched.current = true;

    const query = new URLSearchParams(location.search);
    const sessionId = query.get('session_id');
    const action = query.get('action'); // 'update' o undefined

    console.log(`Session ID: ${sessionId}, Action: ${action}`);

    if (sessionId) {
      const endpoint = action === 'update' ? '/verify-subscription-update' : '/verify-session';
      console.log(`Calling endpoint: ${endpoint}`);

      api.post(endpoint, { sessionId })
        .then(response => {
          setStatus('success');
          setMessage(action === 'update' 
            ? 'Tu suscripción ha sido actualizada exitosamente.' 
            : 'Tu pago ha sido exitoso y el servicio ha sido contratado.');

          // Opcional: Refrescar datos del usuario/plans
          // fetchData();
        })
        .catch(error => {
          console.error('Error al verificar la sesión:', error);
          setStatus('error');
          setMessage(action === 'update' 
            ? 'Hubo un problema al actualizar tu suscripción. Por favor, contacta al soporte.' 
            : 'Hubo un problema al procesar tu pago. Por favor, contacta al soporte.');
        });
    } else {
      setStatus('error');
      setMessage('No se encontró una sesión válida.');
    }
  }, [location.search]);

  if (status === 'loading') {
    return (
      <Container maxWidth="sm" sx={{ mt: 10, textAlign: 'center' }}>
        <CircularProgress />
        <Typography sx={{ mt: 2 }}>Procesando tu pago...</Typography>
      </Container>
    );
  }

  return (
    <Container maxWidth="sm" sx={{ mt: 10, textAlign: 'center' }}>
      {status === 'success' ? (
        <Alert severity="success">{message}</Alert>
      ) : (
        <Alert severity="error">{message}</Alert>
      )}
      <Typography sx={{ mt: 2 }}>
        {status === 'success' 
          ? (location.search.includes('action=update') 
              ? '¡Tu suscripción ha sido actualizada con éxito!' 
              : '¡Gracias por tu compra!') 
          : 'Por favor, intenta nuevamente o contacta al soporte.'}
      </Typography>
      <Button variant="contained" sx={{ mt: 2 }} onClick={() => navigate('/dashboard')}>
        Volver al Dashboard
      </Button>
    </Container>
  );
};

export default PaymentSuccess;
