// src/pages/ContratarWidgetInput.jsx
import React, { useState, useEffect } from 'react';
import { Container, Typography, TextField, Button, Box, Alert, FormControl, InputLabel, Select, MenuItem, CircularProgress } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext'; // Asegúrate de tener acceso al usuario
import api from '../../services/api';

const ContratarWidgetInput = () => {
  const { user } = useAuth(); // Obtener el usuario actual
  const [widgetUrl, setWidgetUrl] = useState('');
  const [billingDay, setBillingDay] = useState(1); // Valor por defecto
  const [originalBillingDay, setOriginalBillingDay] = useState(1);
  const [showWarning, setShowWarning] = useState(false);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(true); // Estado de carga
  const navigate = useNavigate();

  useEffect(() => {
    const fetchBillingDay = async () => {
      try {
        const response = await api.get(`/customer-details?user_id=${user.id}`);
        const billingCycle = response.data.billing_cycle ? parseInt(response.data.billing_cycle, 10) : 1;
        setBillingDay(billingCycle);
        setOriginalBillingDay(billingCycle); // Guardar el día original
      } catch (err) {
        console.error('Error al obtener billing_cycle:', err);
        setError('No se pudo obtener el día de facturación.');
      } finally {
        setLoading(false);
      }
    };

    if (user && user.id) {
      fetchBillingDay();
    }
  }, [user]);

  const handleNext = async () => {
    try {
      let domain = widgetUrl.trim();

      if (/^https?:\/\//i.test(domain)) {
        const url = new URL(domain);
        domain = url.hostname;
      }

      const domainPattern = /^([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}$/;
      if (!domainPattern.test(domain)) {
        throw new Error('Formato de dominio inválido.');
      }

      const response = await api.post('/check-domain', { widgetUrl: domain });

      if (response.data.available) {
        navigate('/dashboard/contratar-widget/pricing', { state: { widgetUrl: domain, billingDay } });
      }
    } catch (err) {
      if (err.response && err.response.data && err.response.data.error) {
        setError(err.response.data.error);
      } else if (err.message === 'Formato de dominio inválido.') {
        setError('Por favor, ingresa un dominio válido (e.g., www.ejemplo.com o ejemplo.com).');
      } else {
        setError('Por favor, ingresa una URL válida (e.g., https://www.ejemplo.com o www.ejemplo.com).');
      }
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === 'billingDay') {
      const selectedDay = parseInt(value, 10);
      setBillingDay(selectedDay);
      setShowWarning(selectedDay !== originalBillingDay); // Mostrar advertencia si es diferente
    } else if (name.startsWith('billing_address.')) {
/*       const field = name.split('.')[1]; */
      setWidgetUrl(widgetUrl); // No es necesario en este caso
    } else {
      setWidgetUrl(widgetUrl); // No es necesario en este caso
    }
  };

  if (loading) {
    return (
      <Container maxWidth="sm" sx={{ mt: 10, textAlign: 'center' }}>
        <CircularProgress />
        <Typography sx={{ mt: 2 }}>Cargando datos...</Typography>
      </Container>
    );
  }

  return (
    <Container maxWidth="sm" sx={{ mt: 10 }}>
      <Typography variant="h4" gutterBottom>
        Contratar Widget
      </Typography>
      <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
        Ejemplo: www.ejemplo.com o ejemplo.com
      </Typography>
      {error && <Alert severity="error">{error}</Alert>}
      <Box sx={{ mt: 2 }}>
        <TextField
          fullWidth
          label="Dominio donde deseas añadir el widget"
          value={widgetUrl}
          onChange={(e) => setWidgetUrl(e.target.value)}
          required
        />
        <FormControl fullWidth sx={{ mt: 2 }}>
          <InputLabel id="billing-day-label">Día de Facturación</InputLabel>
          <Select
            labelId="billing-day-label"
            id="billing-day-select"
            name="billingDay"
            value={billingDay}
            label="Día de Facturación"
            onChange={handleChange}
          >
            <MenuItem value={1}>1</MenuItem>
            <MenuItem value={8}>8</MenuItem>
            <MenuItem value={15}>15</MenuItem>
            <MenuItem value={22}>22</MenuItem>
          </Select>
        </FormControl>

        {showWarning && (
          <Alert severity="warning" sx={{ mt: 2 }}>
            Se recomienda que todos los servicios tengan el mismo día de facturación.
          </Alert>
        )}

        <Button
          variant="contained"
          color="primary"
          sx={{ mt: 3 }}
          onClick={handleNext}
          fullWidth
        >
          Siguiente
        </Button>
      </Box>
    </Container>
  );
};

export default ContratarWidgetInput;
