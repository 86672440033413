// src/pages/AuthCallback.jsx
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';
import api from '../../services/api';
import { CircularProgress, Box, Typography } from '@mui/material';

const AuthCallback = () => {
  const { user, loading } = useAuth();
  const navigate = useNavigate();

  console.log('hola')

  useEffect(() => {
    const checkProfile = async () => {
      if (!loading && user) {
        try {
          const response = await api.get('/customer-details', {
            params: { user_id: user.id },
          });

          console.log(response)

          const {
            email,
            tipoCuenta,
            telefono,
            nombre,
            billing_cycle,
            billing_address,
            fiscal_identification,
          } = response.data;

          const isProfileComplete =
            email &&
            tipoCuenta &&
            telefono &&
            nombre &&
            fiscal_identification &&
            billing_cycle &&
            billing_address &&
            billing_address.city &&
            billing_address.region &&
            billing_address.address &&
            billing_address.postal_code &&
            billing_address.country_code ;

          console.log('Detalles del cliente:', response.data);
          console.log('Perfil completo:', isProfileComplete);

          if (isProfileComplete) {
            // Perfil completo, redirigir al dashboard
            navigate('/dashboard');
          } else {
            // Perfil incompleto, redirigir a los pasos de registro con estado
            navigate('/register', { state: { fromOAuth: true } });
          }
        } catch (error) {
          if (error.response && error.response.status === 404) {
            // Cliente no encontrado, redirigir a registro
            navigate('/register', { state: { fromOAuth: true } });
          } else {
            console.error('Error al verificar el perfil del usuario:', error);
            navigate('/register', { state: { fromOAuth: true } });
          }
        }
      }
    };

    checkProfile();
  }, [user, loading, navigate]);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mt: 10 }}>
      <CircularProgress />
      <Typography variant="h6" sx={{ mt: 2 }}>
        Verificando tu cuenta...
      </Typography>
    </Box>
  );
};

export default AuthCallback;
